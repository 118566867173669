import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb el marge del capell característic amb estries molt profundes. El capell, gris cendra, té de 8 a 15 cm de diàmetre primer hemisfèric i després obert amb el centre enfonsat. Pot presentar restes de vel universal en forma de plaques amples. Les làmines són blanquinoses, grans, més o menys atapeïdes amb nombroses lamel·les. El peu és blanc, amb bandes de color crema és llarg, robust i presenta a la base una volva gruixada, quasi triangular, amb el marge superior separat del peu. Les espores són blanques en massa, ovoïdals, d’10-13 x 7,5-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      